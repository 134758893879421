<template>
  <div
    v-if="tabList.length"
  >
    <div
      role="tabList"
      :aria-label="label"
      :aria-orientation="orientation"
      :class="tabListClasses"
    >
      <button
        v-for="(tab, index) in tabList"
        :id="`${id}-tab-${index}`"
        :key="tab.id"
        ref="tab"
        :aria-selected="index === activeIndex"
        :tabindex="index === activeIndex ? false : -1"
        :aria-controls="`${id}-panel-${index}`"
        role="tab"
        :class="tabClasses"
        @keydown="onKeydown"
        @click="onTabPanelPress(index, tab.id)"
      >
        {{ tab.label }}
      </button>
    </div>

    <div
      v-for="(tab, index) in tabList"
      :id="`${id}-panel-${index}`"
      :key="tab.id"
      :aria-labelledby="`${id}-tab-${index}`"
      :hidden="index !== activeIndex"
      role="tabpanel"
      :class="panelClasses"
    >
      <slot
        :name="tab.id"
      />
    </div>
  </div>
</template>

<script>
import { v4 as UUID } from 'uuid';
import keycodes from '@/helpers/keycodes';


/* Original source code from vuetensils */
/**
 * Show and hide content based on which tabs are selected.
 *
 * Implements best practices for accessible tab components based on W3C.
 * Includes HTML5 role attributes (tablist, tab, tabpanel),
 * aria attributes (aria-label, aria-selected, aria-controls, aria-labelledby), and ideal keyboard navigation.
 *
 * Keyboard navigation to the tabs only targets active tab. `right` key activates next tab (horizontal orientation)
 * or loops around to start. `left` key activates previous tab (horizontal orientation)
 * or loops around to end. `down` key activates next tab (vertical orientation) or loops around to start. `down` key
 * activates previous tab (vertical orientation) or loops around to end. (in horizontal orientation), `home` key
 * activates first tab. `end` key activates last tab.
 */
export default {
  props: {
    /**
     * Support for aria-label attribute
     */
    label: String,
    /**
     * Support for aria-orientation attribute
     */
    orientation: {
      type: String,
      default: 'horizontal',
    },
    tabList: {
      type: Array,
      default: () => [],
    },
    classes: {
      type: Object,
      default: () => ({}),
    },
    tabClasses: {
      type: [Array, Object, String],
      default: '',
    },
    tabListClasses: {
      type: [Array, Object, String],
      default: '',
    },
    tabPanelClasses: {
      type: [Array, Object, String],
      default: '',
    },
    panelClasses: {
      type: [Array, Object, String],
      default: '',
    },
  },
  data: () => ({
    activeIndex: 0,
    id: '',
  }),
  created() {
    if (this.$attrs.id) this.id = this.$attrs.id;
    else this.id = UUID();
  },
  methods: {
    onKeydown(event) {
      const { keyCode } = event;

      // eslint-disable-next-line default-case
      switch (keyCode) {
        case keycodes.END:
          event.preventDefault();
          this.activeIndex = this.tabList.length - 1;
          this.setFocus();
          break;
        case keycodes.HOME:
          event.preventDefault();
          this.activeIndex = 0;
          this.setFocus();
          break;
        // Up and down are in keydown because we need to prevent page scroll >:)
        case keycodes.LEFT:
        case keycodes.RIGHT:
        case keycodes.UP:
        case keycodes.DOWN:
          this.determineOrientation(event);
          break;
      }
    },
    // When a tablist's aria-orientation is set to vertical, only up and down arrow should function.
    // In all other cases only left and right arrow function.
    determineOrientation(event) {
      const { keyCode } = event;
      let proceed = false;

      if (this.orientation === 'vertical') {
        if (keyCode === keycodes.UP || keyCode === keycodes.DOWN) {
          event.preventDefault();
          proceed = true;
        }
      } else if (keyCode === keycodes.LEFT || keyCode === keycodes.RIGHT) {
        proceed = true;
      }
      if (proceed) {
        this.switchTabOnArrowPress(event);
        this.setFocus();
      }
    },
    // Either focus the next, previous, first, or last tab depening on key pressed
    switchTabOnArrowPress(event) {
      const { keyCode } = event;
      const directions = {
        [keycodes.LEFT]: -1,
        [keycodes.UP]: -1,
        [keycodes.RIGHT]: 1,
        [keycodes.DOWN]: 1,
      };

      if (!directions[keyCode]) return;

      const tabLength = this.$refs.tab.length;
      const nextIndex = this.activeIndex + directions[keyCode];

      if (nextIndex < 0) {
        this.activeIndex = tabLength - 1;
      } else if (nextIndex >= tabLength) {
        this.activeIndex = 0;
      } else {
        this.activeIndex = nextIndex;
      }
    },
    setFocus() {
      this.$refs.tab[this.activeIndex].focus();
    },
    onTabPanelPress(index, tabId) {
      this.activeIndex = index;
      this.$emit('pressed-tab-panel', tabId);
    },
  },
};
</script>
