<template>
  <component :is="tag" class="flex justify-between px-15 bg-white print:py-5 border-b border-gray-200 transition-colors duration-100
                                      ease-in-out-quart laptop:pl-20 laptop:pr-5 print:rounded-none">
    <div class="w-full flex items-center">
      <p v-if="tabName !== 'active'" class="w-100 font-medium mr-15 laptop:text-lg laptop:mr-20 laptop-l:w-150">
        {{ order.humanReadableId }}
      </p>
      <RouterLink v-else :to="{ name: 'OrderEdit', params: { locale: $i18n.locale, id: order.id, update: true } }"
        class="w-100 text-link underline font-medium mr-15 laptop:text-lg laptop:mr-20 laptop-l:w-150">
        {{ order.humanReadableId }}
      </RouterLink>
      <p class="w-125 text-sm leading-tight mr-15 line-clamp-2 laptop:w-140 laptop:text-base laptop:mr-20 laptop-l:w-200">
        {{ order.commission || '-' }}
      </p>
      <p class="w-125 text-sm leading-tight mr-15 line-clamp-2 laptop:w-150 laptop:text-base laptop:mr-20 laptop-l:w-200">
        {{ order.orderNumber || '-' }}
      </p>
      <p class="flex-grow text-sm text-gray-800 laptop:text-base laptop:mr-20">
        {{ order.createdTimeLocaleDate || '-' }}
      </p>
    </div>
    <div class="flex-shrink-0 flex items-center">
      <!-- <button
        class="items-center print:hidden"
        @click="$emit('print', order.id)"
      >
        <IconPrint class="icon-print h-20 w-20 text-gray-900 mr-10" />
        <span class="text-gray-600">Vorschau</span>
      </button> -->
      <RouterLink v-if="tabName !== 'active'"
        :to="{ name: 'OrderPreview', params: { locale: $i18n.locale, id: order.id, update: true } }"
        class="items-center print:hidden">
        {{ $t('order_item.preview') }}
      </RouterLink>
      <button v-if="tabName !== 'active'"
        class="button button-secondary button-lg button-text items-center hidden mr-5 laptop:flex print:hidden"
        @click="$emit('print', order.id)">
        <IconPrint class="icon-print h-20 w-20 text-gray-900 mr-10" />
        <span class="text-gray-600">{{ $t('order_item.print') }}</span>
      </button>
      <UIDropdown trigger-classes="button button-secondary button-text h-40 w-40 flex items-center justify-center py-2
                                          rounded-full focus:outline-none" @click="elevate = !elevate">
        <template #trigger>
          <IconDotsHorizontal class="h-25 w-25 text-gray-800 print:hidden" />
        </template>
        <div class="rounded-lg shadow-xs">
          <ul class="min-w-content py-5 bg-white rounded-lg shadow-xl">
            <li>
              <button class="button button-secondary button-lg button-text button-rounded-none flex items-center
                                                  laptop:hidden" @click="$emit('print', order.id)">
                <IconPrint class="icon-print h-20 w-20 text-gray-900 mr-15" />
                {{ $t('order_item.print') }}
              </button>
            </li>
            <li v-if="tabName === 'active'">
              <RouterLink :to="{ name: 'OrderEdit', params: { locale: $i18n.locale, id: order.id, update: true } }"
                class="button button-secondary button-lg button-text button-rounded-none flex items-center">
                <IconEdit class="flex-shrink-0 h-20 w-20 mr-15" />
                {{ $t('generic_crud_labels.edit') }}
              </RouterLink>
            </li>
            <li v-if="tabName !== 'pending' && tabName !== 'archived'">
              <button class="button button-danger button-lg button-text button-rounded-none w-full flex items-center"
                @click="$emit('delete', order.id)">
                <IconTrash class="flex-shrink-0 h-20 w-20 mr-15" />
                {{ $t('order_item.delete') }}
              </button>
            </li>
            <li v-if="tabName !== 'active'">
              <!-- <RouterLink
                :to="{ name: 'OrderEdit', params: { locale: $i18n.locale, id: order.id, update: false, duplicate: true } }"
                class="button button-secondary button-lg button-text button-rounded-none flex items-center">
                <IconCopy class="flex-shrink-0 h-20 w-20 mr-15" />
                {{ $t('order_item.duplicate') }}
              </RouterLink> -->

              <button class="button button-secondary button-lg button-text button-rounded-none flex items-center"
                @click="$emit('duplicate', order.id)">
                <IconCopy class="flex-shrink-0 h-20 w-20 mr-15" />
                {{ $t('order_item.duplicate') }}
              </button>
            </li>
          </ul>
        </div>
      </UIDropdown>
    </div>
  </component>
</template>

<script>
import UIDropdown from '@/components/UI/UIDropdown';
import IconDotsHorizontal from '@/components/icon/IconDotsHorizontal';
import IconEdit from '@/components/icon/IconEdit';
import IconPrint from '@/components/icon/IconPrint';
import IconTrash from '@/components/icon/IconTrash';
import IconCopy from '@/components/icon/IconCopy';

export default {
  props: {
    tag: {
      type: String,
      default: 'li',
    },
    order: {
      type: Object,
      // default: () => ({
      //   date: new Date(),
      //   orderNumber: '',
      //   commission: '',
      // }),
    },
    /**
     * active, pending, archived
     */
    tabName: {
      type: String,
      default: '',
    },
  },
  components: {
    UIDropdown,
    IconDotsHorizontal,
    IconEdit,
    IconPrint,
    IconTrash,
    IconCopy,
  },
};
</script>

<style scoped>
.icon-print .primary {
  @apply fill-gray-400;
}
</style>
