<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :class="['icon-dots-horizontal', data.class, data.staticClass]"
  >
    <path
      fill-rule="evenodd"
      d="M5 14a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm7 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm7 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
      class="secondary fill-current"
    />
  </svg>
</template>


<script>
export default {};
</script>
