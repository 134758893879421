<template>
  <div class="h-full">
    <Portal to="title">
      {{ $t('orders_view.your_orders') }}
    </Portal>
    <Portal to="header">
      <label class="relative h-40 inline-block">
        <input v-model="search.text" ref="search-input" :placeholder="$t('navi.search_orders')"
          class="max-w-300 h-full w-full text-secondary tracking-wide pl-45 pr-40 bg-white rounded-lg shadow
                                                                                                                                transition-colors duration-200 ease-in-out-quart focus:outline-none"
          :class="{ 'bg-gray-100': scrollY > 0 }" @input="debounceInput($event.target.value)">
        <IconSearch
          class="absolute top-0 bottom-0 h-22 w-22 ml-10 my-auto transition-colors duration-200 ease-in-out-quart"
          :class="[search.text ? 'text-secondary' : 'text-gray-600']" />
        <Transition name="transition-fade-slow">
          <button v-show="search.text !== ''"
            class="button button-secondary button-text button-rounded-full absolute top-0 bottom-0 right-0 h-40 w-40
                                                                                                                                  flex items-center justify-center"
            @click="clearSearch">
            <IconClose class="text-gray-700 h-35 w-35" />
          </button>
        </Transition>
      </label>
    </Portal>
    <Transition name="transition-fade-fast" mode="out-in">
      <div v-if="search.text" :class="{ 'print:hidden': orderToPrint.formData }">
        <Transition name="transition-fade-fast" mode="out-in">
          <div v-if="search.results.length && !activeOrders.initialLoading && !archivedOrders.initialLoading">
            <p class="ui-card py-20 px-20">
              {{ $t('orders.search_results') }} "
              <span class="font-bold text-lg">
                {{ search.text }}
              </span>
              "
            </p>
            <OrderList :orders="search.results" class="ui-card flex justify-between mt-10"
              :class="[!search.showResults ? 'opacity-50 pointer-events-none' : '']" @print="printOrder($event)"
              @delete="confirmOrderDeletion($event)" @duplicate="duplicateOrder($event)" />
          </div>
          <div v-else-if="search.showResults && !activeOrders.initialLoading && !archivedOrders.initialLoading"
            class="ui-card flex flex-col items-center py-30 px-20">
            <IconSearch class="h-50 text-secondary mb-10" />
            {{ $t('orders_view.no_search_results') }}
          </div>
        </Transition>
      </div>
      <UITabs v-else :label="$t('orders_view.your_orders')" :tab-list="tabList"
        tab-list-classes="h-50 inline-flex mb-10 bg-white rounded-md shadow overflow-hidden"
        tab-classes="button button-secondary button-text w-210 button-rounded-none print:hidden" class="order-tabs w-full"
        :class="{ 'print:hidden': orderToPrint.formData }" @pressed-tab-panel="activeTabId = $event">
        <template slot="active">
          <OrderList v-if="$store.getters['user/orders/active'].length && !activeOrders.initialLoading"
            :orders="$store.getters['user/orders/active']" :tab-name="'active'" class="ui-card flex justify-between"
            @print="printOrder($event)" @delete="confirmOrderDeletion($event)" @duplicate="duplicateOrder($event)" />
          <div v-else-if="!activeOrders.initialLoading" class="ui-card flex flex-col items-center py-30 px-20">
            <IconBox class="icon-box h-50 text-gray-400 mb-10" />
            <template v-if="!archivedOrders.initialLoading && !$store.getters['user/orders/archived'].length">
              {{ $t('orders_view.no_orders_yet') }}
            </template>
            <template v-else>
              {{ $t('orders_view.no_orders_drafts') }}
            </template>
          </div>
        </template>
        <template slot="pending">
          <template v-if="$store.getters['user/orders/pending'].length && !pendingOrders.initialLoading">
            <OrderList :orders="$store.getters['user/orders/pending']" :tab-name="'pending'"
              class="ui-card flex justify-between" @print="printOrder($event)" @delete="confirmOrderDeletion($event)"
              @duplicate="duplicateOrder($event)" />
          </template>
          <div v-else-if="!pendingOrders.initialLoading" class="ui-card flex flex-col items-center py-30 px-20">
            <IconBox class="icon-box h-50 text-gray-400 mb-10" />
            <template v-if="!activeOrders.initialLoading && !$store.getters['user/orders/active'].length">
              {{ $t('orders_view.no_orders_yet') }}
            </template>
            <template v-else>
              {{ $t('orders_view.no_pending_orders') }}
            </template>
          </div>
        </template>
        <template slot="archived">
          <template v-if="$store.getters['user/orders/archived'].length && !archivedOrders.initialLoading">
            <p class="flex items-center p-20 mb-10 bg-white shadow rounded-md print:hidden">
              <IconInformation class="icon-information h-30 w-30 text-primary mr-15" />
              {{ $t('orders_view.archive_orders_note') }}
            </p>
            <OrderList :orders="$store.getters['user/orders/archived']" :tab-name="'archived'"
              class="ui-card flex justify-between" @print="printOrder($event)" @delete="confirmOrderDeletion($event)"
              @duplicate="duplicateOrder($event)" />
          </template>
          <div v-else-if="!archivedOrders.initialLoading" class="ui-card flex flex-col items-center py-30 px-20">
            <IconBox class="icon-box h-50 text-gray-400 mb-10" />
            <template v-if="!activeOrders.initialLoading && !$store.getters['user/orders/active'].length">
              {{ $t('orders_view.no_orders_yet') }}
            </template>
            <template v-else>
              {{ $t('orders_view.no_archived_orders') }}
            </template>
          </div>
        </template>
      </UITabs>
    </Transition>
    <UIModal v-model="modal.isShowing" :title="$t('orders_view.delete_order')"
      :content-description="$t('orders_view.delete_confirm')">
      <template #header="{ hideModal }">
        <div class="flex items-center justify-between pl-22 pr-15 my-15 tablet:pl-25 tablet:mt-20">
          <h2 class="font-medium text-xl leading-tight line-clamp-2">
            {{ $t('orders_view.delete_order') }}
          </h2>
          <button :disabled="modal.isLoading"
            class="self-start flex-shrink-0 hidden h-40 w-40 flex items-center justify-center -my-5 ml-15 rounded-full
                                                                                                                                  focus:outline-none tablet:block any-pointer-fine:block"
            @click="hideModal">
            <IconClose class="h-full w-full text-2xl text-gray-600" />
          </button>
        </div>
      </template>
      <template #default>
        <div class="flex flex-col items-center px-25 mb-10 tablet:mb-20">
          <div class="relative h-70 w-70 flex items-center justify-center bg-gray-200 rounded-full">
            <IconTrash class="icon-trash h-35 text-danger" />
          </div>
          <p class="mt-20">
            {{ $t('orders_view.delete_confirm') }}
          </p>
        </div>
      </template>
      <template #footer>
        <div
          class="flex items-center justify-between py-20 px-20 mobile-l:pb-15 mobile-l:pt-12
                                                                                                                                touch-device:flex-row-reverse">
          <div class="flex -mx-5 touch-device:flex-row-reverse">
            <div class="px-5">
              <button class="button button-danger button-lg button-filled" @click="deleteOrder(modal.orderId)">
                {{ $t('order_item.delete') }}
              </button>
            </div>
            <div class="px-5">
              <button class="button button-secondary button-lg button-text" @click="modal.isShowing = false">
                {{ $t('generic_crud_labels.back') }}
              </button>
            </div>
          </div>
        </div>
      </template>
    </UIModal>
    <OrderForm v-if="formDataIsReady" :existing-order="orderToPrint.formData"
      :human-readable-id="orderToPrint.humanReadableId" :created-order="orderToPrint.formData.createdTime"
      :modified-order="orderToPrint.formData.modifiedTime" :location="orderToPrint.location" no-transitions
      class="hidden print:block" @images-loaded="orderToPrint.ready = true" />
  </div>
</template>

<script>
import Fuse from 'fuse.js';
import { debounce } from 'lodash-es';
import { useWindowScroll } from '@/composables/useWindowScroll';
import { getUserOrders, getUserOrder, ordersPath } from '@/helpers/firestoreRefs';
import { orderToFormDataStructure } from '@/helpers/orderDataStructure';
import searchableHumanReadableId from '@/helpers/searchableHumanReadableId';
import printWindow from '@/helpers/functions/printWindow';
import UIModal from '@/components/UI/UIModal';
import UITabs from '@/components/UI/UITabs';
import IconBox from '@/components/icon/IconBox';
import IconClose from '@/components/icon/IconClose';
import IconInformation from '@/components/icon/IconInformation';
import IconSearch from '@/components/icon/IconSearch';
import IconTrash from '@/components/icon/IconTrash';
import OrderList from '@/components/order/OrderList';
import OrderForm from '@/components/order/OrderForm';
import filterByStatus from '@/helpers/functions/filterByStatus';
import userOrdersModule from '@/store/modules/user/userOrders';

const orderSearchOptions = {
  threshold: 0.0,
  useExtendedSearch: true,
  keys: [{
    name: 'humanReadableIdForSearch',
    weight: 0.7,
  }, {
    name: 'commission',
    weight: 0.2,
  }, {
    name: 'createdTimeLocaleDate',
    weight: 0.1,
  }],
};

export default {
  components: {
    UIModal,
    UITabs,
    IconBox,
    IconClose,
    IconInformation,
    IconSearch,
    IconTrash,
    OrderList,
    OrderForm,
  },
  setup() {
    const { y } = useWindowScroll();

    return {
      scrollY: y,
    };
  },
  data: () => ({
    modal: {
      isShowing: false,
      orderId: '',
    },
    search: {
      text: '',
      showResults: false,
      results: [],
    },
    orderToPrint: {
      ready: false,
      formData: null,
      humanReadableId: '',
      location: null,
    },
    formDataIsReady: false,
    activeTabId: 'active',
    activeOrders: {
      unsubscribe: null,
      replaceState: true,
      initialLoading: true,
    },
    archivedOrders: {
      unsubscribe: null,
      replaceState: true,
      initialLoading: true,
    },
    pendingOrders: {
      unsubscribe: null,
      replaceState: true,
      initialLoading: true,
    },
    after: false,
  }),
  watch: {
    // after: {
    //   immediate: true,
    //   handler(value) {
    //     if (this.after) {
    //       debugger
    //       this.orderToPrint = {
    //         ready: false,
    //         formData: null,
    //         humanReadableId: '',
    //         location: null,
    //       };
    //       this.formDataIsReady = false
    //     }
    //   },
    // },
    activeTabId: {
      immediate: true,
      handler(value) {
        if (!this.$store.state.user?.orders) this.$store.registerModule(['user', 'orders'], userOrdersModule);

        if (value === 'active' && this.activeOrders.unsubscribe === null) {
          // Replace archived orders on next snapshot
          this.archivedOrders.replaceState = true;
          this.activeOrders.unsubscribe = this.subscribeUserActiveOrders(this.$store.state.user.auth.id);
        } else if (value === 'pending' && this.pendingOrders.unsubscribe === null) {
          this.pendingOrders.replaceState = true;
          this.pendingOrders.unsubscribe = this.subscribeUserPendingOrders(this.$store.state.user.auth.id);
        } else if (this.archivedOrders.unsubscribe === null) { // archived
          // Replace active orders on next snapshot
          this.activeOrders.replaceState = true;

          this.archivedOrders.unsubscribe = this.subscribeUserArchivedOrders(this.$store.state.user.auth.id);
        }
      },
    },
    'search.text': {
      handler() {
        this.search.showResults = false;
      },
    },
    'orderToPrint.ready': {
      async handler(value) {
        if (!value) return;
        console.log('# Print');
        printWindow();
        // if (after) {
        //   debugger
        //   this.orderToPrint = {
        //     ready: false,
        //     formData: null,
        //     humanReadableId: '',
        //     location: null,
        //   };
        //   this.formDataIsReady = false
        // }
        window.onafterprint = this.afterPrint();

        // this.$nextTick(() => {
        //   this.orderToPrint = {
        //     ready: false,
        //     formData: null,
        //     humanReadableId: '',
        //     location: null,
        //   };
        //   this.formDataIsReady = false
        // });
      },
    },
  },
  mounted() {
    if (this.activeOrders.unsubscribe === null) {
      this.activeOrders.replaceState = true;
      this.activeOrders.unsubscribe = this.subscribeUserActiveOrders(this.$store.state.user.auth.id);
    }
    if (this.archivedOrders.unsubscribe === null) {
      this.archivedOrders.replaceState = true;
      this.archivedOrders.unsubscribe = this.subscribeUserArchivedOrders(this.$store.state.user.auth.id);
    }
    if (this.pendingOrders.unsubscribe === null) {
      this.pendingOrders.replaceState = true;
      this.pendingOrders.unsubscribe = this.subscribeUserPendingOrders(this.$store.state.user.auth.id);
    }
  },
  computed: {
    tabList() {
      let activeTabLabel = this.$t('navi.drafts');
      let pendingTabLabel = this.$t('navi.sent');
      let archivedTabLabel = this.$t('navi.archived');

      if (this.$store.getters['user/orders/active']?.length) {
        activeTabLabel += ` (${this.$store.getters['user/orders/active']?.length})`;
      }
      if (this.$store.getters['user/orders/archived']?.length) {
        archivedTabLabel += ` (${this.$store.getters['user/orders/archived']?.length})`;
      }
      if (this.$store.getters['user/orders/pending']?.length) {
        pendingTabLabel += ` (${this.$store.getters['user/orders/pending']?.length})`;
      }

      return [{
        id: 'active',
        label: activeTabLabel,
      }, {
        id: 'pending',
        label: pendingTabLabel,
      }, {
        id: 'archived',
        label: archivedTabLabel,
      }];
    }
  },
  methods: {
    debounceInput: debounce(function (searchText) {
      this.search.results = this.searchForOrder(searchText);
      this.search.showResults = true;
    }, 300),
    clearSearch() {
      this.search.text = '';
      this.$refs['search-input'].blur();
    },
    afterPrint() {
      setTimeout(() => {
        this.orderToPrint = {
          ready: false,
          formData: null,
          humanReadableId: '',
          location: null,
        };
        this.formDataIsReady = false
      }, 4000);
      // this.orderToPrint = {
      //   ready: false,
      //   formData: null,
      //   humanReadableId: '',
      //   location: null,
      // };
      // this.formDataIsReady = false
    },
    searchForOrder(searchText) {
      const fuse = new Fuse(this.$store.getters['user/orders/sortedByCreatedTime'], orderSearchOptions);

      // If "-" is searched, try to match a humanReadableId
      if (searchText.includes('-') && searchText[searchText.length - 1] !== '-') {
        const humanReadableIdSearchResults = fuse.search(`${searchableHumanReadableId(searchText)}`);
        if (humanReadableIdSearchResults.length) {
          const itemsWithoutrefIndex = humanReadableIdSearchResults.map((item) => {
            const newItem = item;
            delete newItem.refIndex;
            return newItem.item;
          });
          return itemsWithoutrefIndex;
        }
      }
      const foundItems = fuse.search(`${searchText} | ${searchText}$ | ^${searchText}`);
      const itemsWithoutrefIndex = foundItems.map((item) => {
        const newItem = item;
        delete newItem.refIndex;
        return newItem.item;
      });
      return itemsWithoutrefIndex;
    },
    async printOrder(id) {
      const order = this.$store.state.user?.orders?.all[id];

      let { formData } = await orderToFormDataStructure(order);
      formData.header = { ...formData.header, ...{ orderDate: formData.header.createdTime } }
      this.$set(this.orderToPrint, 'formData', formData);
      this.formDataIsReady = true;
      this.orderToPrint.humanReadableId = order.humanReadableId || '';
      this.orderToPrint.location = order.location;
    },
    confirmOrderDeletion(id) {
      this.modal.orderId = id;
      this.modal.isShowing = true;
    },
    duplicateOrder(orderId) {
      console.log('Duplicating ' + orderId)
      // get user order details
      getUserOrder(orderId)
        .get()
        .then(async (orderSnapshot) => {
          const order = {
            ...orderSnapshot.data()
          }
          order.orderDate = new Date()
          order.status = 0 //fresh order
          // order.humanReadableId = ''
          order.createdTime = new Date()
          order.modifiedTime = new Date()
          order.deliveryDate = ''
          order.pickupDate = ''

          try {
            const querySnapshot = await getUserOrders(order.createdBy).orderBy('humanReadableId', 'asc').get()
            const orders = querySnapshot.docs;
            orders.sort((prevOrder, nextOrder) => {
              const prevOrderNumber = parseInt(prevOrder.data().humanReadableId.split('-')[1], 10);
              const nextOrderNumber = parseInt(nextOrder.data().humanReadableId.split('-')[1], 10);
              return prevOrderNumber - nextOrderNumber;
            })
            // Get orders humanReadableId with digital ending
            const filteredOrders = orders.filter((order) => {
              const idParts = order.data().humanReadableId.split('-');
              const idPartsNum = idParts[idParts.length - 1];
              if (Number.isInteger(+idPartsNum)) return order;
            })
            const lastOrderIndex = filteredOrders.length ? filteredOrders.length - 1 : orders.length - 1;
            const idParts = filteredOrders.length ? filteredOrders[lastOrderIndex].data().humanReadableId.split('-') : orders[lastOrderIndex].data().humanReadableId.split('-');
            let idPartsNum = idParts[idParts.length - 1];
            const idPartsKey = idParts[0];
            if (idPartsNum === 'F') idPartsNum = 0;
            order.humanReadableId = `${idPartsKey}-${parseInt(idPartsNum, 10) + 1}`

            const docRef = await ordersPath.add(order)
            this.$router.replace({
              name: 'OrderEdit',
              params: {
                locale: this.$i18n.locale, id: docRef.id, update: true,
              },
            })

          } catch (error) {
            console.log(error)
          }
        })
    },
    deleteOrder(orderId) {
      getUserOrder(orderId)
        .delete()
        .then(() => {
          this.modal.orderId = '';
          this.modal.isShowing = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    subscribeUserActiveOrders(userId) {
      return getUserOrders(userId)
        .where('status', '==', 0)
        .onSnapshot((snapshot) => {
          this.$store.dispatch('user/orders/onOrdersSnapshot', {
            snapshot,
            replaceState: this.activeOrders.replaceState ? {
              filter: filterByStatus,
              params: [0],
            } : false,
          });
          this.activeOrders.initialLoading = false;
        }, (error) => {
          console.error(error);
        });
    },
    subscribeUserArchivedOrders(userId) {
      return getUserOrders(userId)
        .where('status', '==', 1)
        .onSnapshot((snapshot) => {
          this.$store.dispatch('user/orders/onOrdersSnapshot', {
            snapshot,
            replaceState: this.archivedOrders.replaceState ? {
              filter: filterByStatus,
              params: [1],
            } : false,
          });
          this.archivedOrders.initialLoading = false;
        }, (error) => {
          console.error(error);
        });
    },
    subscribeUserPendingOrders(userId) {
      return getUserOrders(userId)
        .where('status', '==', 3)
        .onSnapshot((snapshot) => {
          this.$store.dispatch('user/orders/onOrdersSnapshot', {
            snapshot,
            replaceState: this.pendingOrders.replaceState ? {
              filter: filterByStatus,
              params: [3],
            } : false,
          });
          this.pendingOrders.initialLoading = false;
        }, (error) => {
          console.error(error);
        });
    },
  },
  beforeDestroy() {
    if (this.activeOrders.unsubscribe !== null) this.activeOrders.unsubscribe();
    if (this.archivedOrders.unsubscribe !== null) this.archivedOrders.unsubscribe();
    if (this.pendingOrders.unsubscribe !== null) this.pendingOrders.unsubscribe();
  },
};
</script>

<style scoped>
/*
# Available configuration classes
.tabs-selector [role=tablist]
  .tabs-selector [role=tab]
.tabs-selector [role=tabpanel]
*/
.order-tabs {
  &>>>[role=tab] {
    @apply text-gray-700 bg-gray-100;
  }

  &>>>[role=tab][aria-selected=true] {
    @apply font-bold text-primary bg-white border-b-3 border-primary;
  }

  /* Shadow inset styling */

  &>>>[role=tab][aria-selected=true]:first-child {
    /* For the first selected tab */
    @apply shadow-gray-200-inset-r;
  }

  &>>>[role=tab][aria-selected=true] {
    /* For any selected tab that is not first & last (Only for 3+ tabs) */
    @apply shadow-gray-200-inset-r shadow-gray-200-inset-l;
  }

  &>>>[role=tab][aria-selected=true]:last-child {
    /* For the last selected tab */
    @apply shadow-gray-200-inset-l;
  }
}

.ui-card {
  @apply bg-white shadow rounded-md;
}

.icon-information {
  &>>>.secondary {
    @apply fill-white;
  }
}

.icon-trash {
  &>>>.secondary {
    @apply fill-danger-dark;
  }
}

.icon-box {
  &>>>.primary {
    @apply fill-secondary;
  }
}
</style>
