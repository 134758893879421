<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :class="['icon-search', data.class, data.staticClass]"
  >
    <circle
      cx="10"
      cy="10"
      r="7"
      class="primary fill-transparent"
    />
    <!-- eslint-disable -->
    <path
      d="M16.32 14.9l1.1 1.1c.4-.02.83.13 1.14.44l3 3a1.5 1.5 0 0 1-2.12 2.12l-3-3a1.5 1.5 0 0 1-.44-1.14l-1.1-1.1a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"
      class="secondary fill-current"
    />
    <!-- eslint-enable -->
  </svg>
</template>


<script>
export default {};
</script>
