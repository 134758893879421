<template>
  <div class="w-full flex flex-col shadow rounded-md">
    <div class="sticky top-header h-50 flex items-center justify-start font-medium text-xs text-secondary uppercase
              tracking-wide py-10 px-15 bg-gray-100 border-t border-b border-gray-200 z-40 laptop:h-55 laptop:text-sm
              laptop:px-20">
      <p class="w-100 mr-15 laptop:mr-20 laptop-l:w-150">
        {{ 'e' + $t('order_header.order_number') }}
      </p>
      <p class="w-125 mr-15 leading-tight laptop:w-140 laptop:mr-20 laptop-l:w-200">
        {{ $t('order_header.commision') }}
      </p>
      <p class="w-125 mr-15 laptop:w-150 laptop:mr-20 laptop-l:w-200">
        {{ $t('order_header.order_nr') }}
      </p>
      <p class="flex-grow">
        {{ $t('order_header.date') }}
      </p>
    </div>
    <RecycleScroller class="list-scroller h-full w-full" :items="orders" :item-size="listItemHeight" :buffer="1000"
      page-mode>
      <template #default="{ item: order }">
        <OrderListItem :order="order" tag="div" :tab-name="tabName" :style="{ height: `${listItemHeight}px` }"
          class="list-item" @print="$emit('print', $event)" @delete="$emit('delete', $event)"
          @duplicate="$emit('duplicate', $event)" />
      </template>
    </RecycleScroller>
  </div>
</template>

<script>
import RecycleScroller from '@/components/vue-virtual-scroller/RecycleScroller';
import OrderListItem from '@/components/order/OrderListItem';

export default {
  props: {
    orders: {
      type: Array,
      default: () => [],
    },
    tabName: {
      type: String,
      default: '',
    },
  },
  components: {
    RecycleScroller,
    OrderListItem,
  },
  data: () => ({
    listItemHeight: 50,
  }),
};
</script>


<style scoped>
.list-scroller {
  &>>>.vue-recycle-scroller__item-wrapper {
    @apply relative overflow-visible;
  }

  &.ready>>>.vue-recycle-scroller__item-view {
    @apply absolute w-full left-0 top-0;

    will-change: transform;

    &.hover .list-item {
      @apply bg-gray-50;
    }
  }

  &>>>.resize-observer {
    @apply absolute top-0 left-0 h-full w-full -z-1 opacity-0 overflow-hidden pointer-events-none;
  }
}
</style>
